import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../../styles/helpers';
import { fontSizes, offsets } from '../../styles';

const baseMDStyles = css`
  font-size: ${fontSizes.xlg};
  margin: ${offsets.xl} auto ${offsets.md};
`;

const baseSMStyles = css`
  font-size: ${fontSizes.lg};
  margin: ${offsets.lg} auto ${offsets.md};
`;

const withSizeStyles = ({ size }) => {
  switch (size) {
    case 'sm':
      return css`
        font-size: ${fontSizes.md};
        margin: ${offsets.lg} auto ${offsets.md};

        ${media.md`
          ${baseSMStyles}
        `}
      `;
    case 'md':
      return css`
        ${baseSMStyles};

        ${media.md`
          ${baseMDStyles}
        `}
      `;

    case 'lg':
      return css`
        ${baseMDStyles}

        ${media.md`
          font-size: 32px;
          margin: ${offsets.xl2} auto 44px;
        `}
      `;

    default:
      return null;
  }
};

const Title = styled.div`
  width: 100%;
  font-weight: 300;
  text-align: ${({ align }) => align};
  ${withSizeStyles}
`;

Title.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

Title.defaultProps = {
  size: 'md',
  align: 'center',
};

export default Title;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal, { BaseModalBackground } from 'styled-react-modal';
import styled from 'styled-components';
import CloseBtn from './close-btn';
import { media } from '../../styles/helpers';

export const ModalBg = styled(BaseModalBackground)`
  background-color: rgba(0, 0, 0, 0.4);
  opacity: ${({ opacity }) => opacity};
  transition: opacity 0.4s ease;
`;

ModalBg.defaultProps = {
  opacity: 0,
};

const ModalContainer = Modal.styled`
  position: relative;
  width: 290px;
  background-color: #fff;
  padding: 24px;
  color: #333;
  border-radius: 4px;
  overflow-y: scroll;
  transition: transform .2s ease-out;
  transform: translateY(${({ position }) => position});
  
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

class StyledModal extends PureComponent {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    bgOpacity: 0,
    modalPosition: '-100vh',
  };

  beforeOpen = () => {
    this.setState({ bgOpacity: 1 }, () =>
      setTimeout(() => {
        this.setState({ modalPosition: 0 });
      }, 100)
    );
  };

  beforeClose = () => {
    this.setState({ modalPosition: '-100vh' });
    this.setState({ bgOpacity: 0 });
    return new Promise(resolve => {
      setTimeout(() => resolve(), 300);
    });
  };

  render() {
    const { toggle, title, isOpen, children } = this.props;
    return (
      <ModalContainer
        isOpen={isOpen}
        onBackgroundClick={toggle}
        onEscapeKeydown={toggle}
        beforeOpen={this.beforeOpen}
        beforeClose={this.beforeClose}
        position={this.state.modalPosition}
        backgroundProps={{ opacity: this.state.bgOpacity }}
      >
        <CloseBtn onClick={toggle} />
        <h2>{title}</h2>
        {children && <Content>{children}</Content>}
      </ModalContainer>
    );
  }
}

export default StyledModal;

import React from 'react';
import styled, { css } from 'styled-components';

const BtnShape = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  z-index: 4;
  outline: none;
  cursor: pointer;

  &:hover {
    span {
      transform: rotate(360deg);
      opacity: 0.7;

      &::after {
        transform: rotate(180deg);
      }
    }
  }
`;

const equalStripStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;
  width: 20px;
  height: 2px;
  background-color: #333;
  transition: 0.2s ease-in-out;
  transform-origin: 50%;
`;

const Strips = styled.span`
  ${equalStripStyles};
  transform: rotate(45deg);

  &::after {
    ${equalStripStyles};
    content: '';
    display: block;
    transform: rotate(90deg);
  }
`;
export default ({ ...rest }) => (
  <BtnShape {...rest}>
    <Strips />
  </BtnShape>
);

import React from 'react';
import styled from 'styled-components';
import { MenuLink } from '../header/nav';
import Nav from './nav';

const Shape = styled.footer`
  display: flex;
  justify-content: center;
  background: #fff;

  ${MenuLink} {
    &::before {
      top: 0;
      bottom: auto;
    }
  }
`;

export default () => (
  <Shape>
    <Nav />
  </Shape>
);

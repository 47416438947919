import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const BtnShape = styled.button`
  display: block;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 4;
  outline: none;
`;

const equalStripStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;
  width: 20px;
  height: 2px;
  background-color: #333;
`;

const Strips = styled.span`
  ${equalStripStyles}

  &::before,
  &::after {
    ${equalStripStyles};
    content: '';
    display: block;
    transition: 0.2s;
  }

  &::before {
    transform: translateY(-8px);
  }

  &::after {
    transform: translateY(8px);
  }

  ${({ active }) =>
    active &&
    css`
      height: 0;
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    `}
`;
const Btn = ({ isOpened, ...rest }) => (
  <BtnShape {...rest}>
    <Strips active={isOpened} />
  </BtnShape>
);

Btn.propTypes = {
  isOpened: PropTypes.bool,
};

Btn.defaultProps = {
  isOpened: false,
};

export default Btn;

import React from 'react';
import { MenuItem, Shape, Menu } from '../header/nav';
import { navItems } from '../../data';

export default () => (
  <Shape>
    <Menu>
      {navItems.map((item, idx) => (
        <MenuItem key={`footer-menu-item-${idx}`} {...item} />
      ))}
    </Menu>
  </Shape>
);

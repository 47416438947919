import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { media } from '../../styles/helpers';
import { offsets, transitions, colors } from '../../styles';
import { TOAST_TTL, hideLastToast } from '../../store/reducers/app';

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 70px;
  padding: ${offsets.md};

  ${media.md`
    top: 0;
  `}
`;

const ItemInner = styled.div`
  width: 300px;
  margin-bottom: ${offsets.xs};
  transition: ${transitions.base};
  padding: ${offsets.sm};
  background: ${colors.success};
  border-radius: 4px;
  transform-origin: 0 0;
  transform: scaleY(${({ visible }) => (visible ? 1 : 0)});
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

class Item extends React.PureComponent {
  state = {
    visible: false,
  };

  componentDidMount = () => setTimeout(this.toggleVisibility, 10);

  toggleVisibility = () =>
    this.setState({ visible: !this.state.visible }, this.destroy);

  destroy = () => {
    if (this.state.visible) {
      setTimeout(() => {
        this.toggleVisibility();
        setTimeout(this.props.destroy, 100);
      }, TOAST_TTL * 0.8);
    }
  };

  render = () => <ItemInner visible={this.state.visible} {...this.props} />;
}

const Toasts = ({ toasts, hideLastToast }) => (
  <Container>
    {toasts.length
      ? toasts.map((toast, idx) => (
          <Item key={`toast-${idx}`} destroy={hideLastToast} dangerouslySetInnerHTML={{ __html: toast }}/>
        ))
      : null}
  </Container>
);

const mapStateToProps = ({ app }) => ({
  toasts: app.toasts,
});

export default connect(
  mapStateToProps,
  { hideLastToast }
)(Toasts);

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../../styles/helpers';
import { offsets, fontSizes, colors } from '../../styles';
import { contacts } from '../../data';

const Shape = styled.div`
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const Phone = styled.a.attrs({
  href: `tel:${contacts['real-tel']}`,
  title: 'Набрать номер',
})`
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: ${fontSizes.xl};

  &:hover {
    color: ${colors.primary};
  }
`;

const Address = styled.div`
  font-weight: 500;
  color: #787878;
  text-align: right;
`;

export default () => (
  <Shape>
    <Phone>{contacts.tel}</Phone>
    <Address>
      пн-ср: 11:00 - 21:00
      <br />
      чт-вс: 11:00 - 22:00
    </Address>
  </Shape>
);

import React from 'react';
import styled from 'styled-components';
import { offsets, colors, fontSizes, transitions } from '../../styles';

export default styled.button`
  display: block;
  padding: ${offsets.xs} ${offsets.sm};
  background: transparent;
  border: 1px solid ${colors.textGray};
  border-radius: 8px;
  font-size: ${fontSizes.md};
  transition: ${transitions.base};
  outline: none;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
    border-color: ${colors.primary};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.99);
  }
`;

import { menuItems } from '../data';
import item from '../containers/menu/items/item';

const KEY = 'mama-pizza-basket';

const serialize = content => JSON.stringify(content);

const parse = raw => JSON.parse(raw);

const setBasketContent = content => {
  localStorage.setItem(KEY, serialize(content));
  return getItems();
};

export const getItems = () => {
  const raw = localStorage.getItem(KEY);
  return parse(raw) || [];
};

export const addItem = newId => {
  const items = getItems();
  const hasAtLeastOneItem = items.some(({ id }) => id === newId);

  return setBasketContent(
    hasAtLeastOneItem
      ? items.map(item =>
          item.id === newId ? { ...item, count: item.count + 1 } : item
        )
      : [...items, { id: newId, count: 1 }]
  );
};

export const removeItemById = id => {
  const items = getItems();

  if (!items.length) {
    return;
  }

  return setBasketContent(items.filter(item => item.id !== id));
};

export const clearBasket = () => setBasketContent([]);

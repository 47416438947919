export const colors = {
  bgGray: '#f5f5f5',
  primary: '#d52427',
  black: '#262322',
  textGray: '#666',
  gray: '#ccc5c4',
  success: '#a8e4b0',
};

export const offsets = {
  xs: '8px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '64px',
  xl2: '72px',
  xl3: '86px',
};

export const transitions = {
  fast: '0.12s ease',
  base: '0.2s ease',
  long: '0.3s ease',
};

export const fontSizes = {
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '22px',
  xl: '24px',
  xlg: '26px',
};

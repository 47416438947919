import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import initReactFastclick from 'react-fastclick';
import { ModalProvider } from 'styled-react-modal';
import Header from '../header';
import Footer from '../footer';
import Toasts from '../toasts';
import BasketWidget from '../../containers/basket-widget';
import { ModalBg } from '../modal';
import { media } from '../../styles/helpers';
import { fontSizes, colors } from '../../styles';
import { getItems } from '../../helpers/basket';
import { updateBasket } from '../../store/reducers/app';
import { Location } from '@reach/router';

import './style.css';

initReactFastclick();

const Main = styled.main`
  font-size: ${fontSizes.xs};

  ${media.sm`
  font-size: ${fontSizes.sm};
  `}

  ${media.md`
  font-size: ${fontSizes.md};
  `}
`;

class Layout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidMount = () => this.props.updateBasket(getItems());

  render() {
    return (
      <ModalProvider backgroundComponent={ModalBg}>
        <>
          <Header />
          <main>{this.props.children}</main>
          <Footer />
          <Toasts />
          <Location>
            {({ navigate, location }) =>
              location.pathname !== '/cart' && <BasketWidget />
            }
          </Location>
        </>
      </ModalProvider>
    );
  }
}

export default connect(
  null,
  { updateBasket }
)(Layout);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';
import { navigate } from 'gatsby';
import { navItems } from '../../data';
import { offsets, fontSizes } from '../../styles';

const MenuWrapper = styled.ul`
  position: absolute;
  top: 59px;
  left: 0;
  right: 0;
  z-index: 29;
  height: calc(100vh - 60px);
  width: 100%;
  background: rgba(255, 255, 255, 0.88);
  transition: transform 0.3s ease;
  transform: translateX(${({ isOpened }) => (isOpened ? '0' : '100vw')});
`;

MenuWrapper.propTypes = {
  isOpened: PropTypes.bool,
};

MenuWrapper.defaultProps = {
  isOpened: false,
};

const MenuItemShape = styled.li`
  padding: ${offsets.md};
  font-size: ${fontSizes.md};
`;

const MenuItem = ({ text, to, scrollTo, toggleMenu }) => (
  <MenuItemShape
    onClick={e => {
      toggleMenu();

      if (!scrollTo) {
        return navigate(to);
      }

      e.preventDefault();
      if (window.location.pathname !== to) {
        navigate(to);
        return setTimeout(() => {
          scrollToElement(scrollTo);
        }, 50);
      }

      return scrollToElement(scrollTo);
    }}
  >
    {text}
  </MenuItemShape>
);

const Menu = ({ toggleMenu, ...rest }) => (
  <MenuWrapper {...rest}>
    {navItems.map((item, idx) => (
      <MenuItem
        key={`mobile-menu-item-${idx}`}
        toggleMenu={toggleMenu}
        {...item}
      />
    ))}
  </MenuWrapper>
);

export default Menu;

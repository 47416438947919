import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Logo from './logo';
import MobileLogo from './logo-mobile';
import MobileMenu from './mobile-menu';
import BurgerBtn from './burger-btn';
import Nav from './nav';
import Contacts from './contacts';
import Container from '../container';
import GlobalWarning from '../layout/global-warning';
import GlobalMobileWarning from '../layout/global-mobile-warning';
import { media } from '../../styles/helpers';

const Shape = styled.header`
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
`;

const Desktop = styled(Container).attrs({ fluid: true })`
  display: none;
  justify-content: space-between;

  ${media.md`
    display: flex;
  `}
`;

const MobileHead = styled.div`
  position: fixed;
  z-index: 39;
  left: 0;
  right: 0;
  top: 0;
`;

const MobileContainer = styled(Container).attrs({ fluid: true })`
  padding-top: 4px;
  padding-bottom: 4px;
  justify-content: space-between;
  background-color: #fff;

  ${media.md`
    display: none;
  `}
`;

class Mobile extends PureComponent {
  state = {
    isOpened: false,
  };

  toggleMenu = () => this.setState({ isOpened: !this.state.isOpened });

  render = () => (
    <MobileHead>
      <MobileContainer>
        <MobileLogo />
        <BurgerBtn isOpened={this.state.isOpened} onClick={this.toggleMenu} />
        <MobileMenu
          isOpened={this.state.isOpened}
          toggleMenu={this.toggleMenu}
        />
      </MobileContainer>
    </MobileHead>
  );
}

export default () => (
  <Shape>
    <Desktop>
      <Logo />
      <Nav />
      <Contacts />
    </Desktop>
    <Mobile />
  </Shape>
);

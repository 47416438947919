import { css } from 'styled-components';

export const sizes = {
  lg: 1200,
  md: 976,
  sm: 540,
};

export const screenSizes = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = `${sizes[label]}px`;
  return accumulator;
}, {});

export default Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Button as BaseButton } from '../../../components/ui';
import { media } from '../../../styles/helpers';
import { offsets, fontSizes, colors } from '../../../styles';
import { addItem } from '../../../helpers/basket';
import { showToast, updateBasket } from '../../../store/reducers/app';

const Shape = styled.div`
  padding: ${offsets.sm};
  flex-basis: 100%;

  ${media.sm`
    flex-basis: 50%;
  `}

  ${media.md`
    flex-basis: 33.3%;
  `}

  ${media.lg`
    flex-basis: 25%;
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${offsets.md};
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.2);
`;

const Image = styled.img`
  display: block;
  height: auto;
  width: 75%;
  margin-bottom: ${offsets.sm};
  overflow: hidden;

  ${({ isImgCircle }) =>
    isImgCircle
      ? css`
          border-radius: 100%;
          box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.4);
        `
      : null}
`;

const Title = styled.div`
  margin-bottom: ${offsets.sm};
  font-size: ${fontSizes.md};
  text-align: center;
  font-weight: bold;
`;

export const Button = styled(BaseButton)`
  margin: auto auto 0;
`;

export const Content = styled.div`
  font-size: ${fontSizes.xs};
  color: ${colors.textGray};
  margin-bottom: ${offsets.sm};
`;

export const Section = styled.div`
  margin-bottom: ${offsets.xs};
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemBasic = ({
  id,
  children,
  showToast,
  updateBasket,
  imgUrl,
  imgAlt,
  isImgCircle,
  name,
}) => (
  <Shape>
    <Item>
      <Image src={imgUrl} alt={imgAlt} isImgCircle={isImgCircle} />
      <Title dangerouslySetInnerHTML={{ __html: name }} />
      <Content>{children}</Content>
      <Button
        onClick={() => {
          const items = addItem(id);
          showToast(`${name} добавлен(а) в корзину`);
          updateBasket(items);
        }}
      >
        В корзину
      </Button>
    </Item>
  </Shape>
);

export default connect(
  null,
  { showToast, updateBasket }
)(ItemBasic);

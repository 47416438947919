import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from '../../styles/helpers';
import { offsets } from '../../styles';

const withAppearanceStyles = ({ fluid }) => {
  if (fluid) {
    return null;
  }

  return css`
    ${media.md`
      max-width: 960px;
    `}

    ${media.lg`
      max-width: 1140px;
    `}
  `;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  padding: 0 ${offsets.sm};
  margin: 0 auto;

  ${withAppearanceStyles}
`;

Container.propTypes = {
  fluid: PropTypes.bool,
  column: PropTypes.bool,
};

Container.defaultProps = {
  fluid: false,
  column: false,
};

export default Container;

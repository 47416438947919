import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/helpers';

const GlobalWarningContainer = styled.div`
  width: 100%;
  padding: 16px;
  background: #c3ff4a;
  z-index: 99;
`;

const warningMessage = "Дорогие клиенты! На время карантина с 1 по 5 апреля кафе и доставка не работают.";

export default () => (
  <GlobalWarningContainer>
    {warningMessage}
  </GlobalWarningContainer>
)

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { media } from '../../styles/helpers';
import { offsets, colors, transitions } from '../../styles';
import Logo from './logo';

const Shape = styled(Link).attrs({ to: '/cart' })`
  position: fixed;
  z-index: 29;
  bottom: 0;
  right: 0;
  padding: ${offsets.md};

  &::before {
    position: absolute;
    background: ${({ isBlinking }) =>
      isBlinking ? '#fff' : 'rgba(255, 255, 255, 0.58)'};
    left: -30px;
    top: -30px;
    width: 230%;
    height: 220%;
    z-index: -1;
    border-radius: 100%;
    transition: ${transitions.base};
    transform: scale(${({ isBlinking }) => (isBlinking ? 1.2 : 1)});
    box-shadow: ${({ isBlinking }) =>
      isBlinking ? '0 0px 30px rgba(0, 0, 0, 0.5)' : 'none'};

    ${media.sm`
      content: '';
      `}
  }

  & svg {
    width: 38px;
    height: 38px;
  }
`;

const Count = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  top: 4px;
  left: 4px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid
    ${({ isEmpty }) => (isEmpty ? colors.bgGray : colors.primary)};
  transition: 0.12s cubic-bezier(0.22, 0.61, 0.36, 1);
  ${({ isBlinking }) =>
    isBlinking
      ? css`
          transform: scale(1.7) translate(-10px, -10px);
        `
      : css`
          transform: scale(1);
          box-shadow: none;
        `};
`;

class BasketWidget extends PureComponent {
  static propTypes = {
    basketItems: PropTypes.number.isRequired,
  };

  state = {
    blink: false,
  };

  blink = () =>
    this.setState({ blink: true }, () =>
      setTimeout(() => this.setState({ blink: false }), 300)
    );

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(props, state) {
    if (this.props.basketItems !== props.basketItems) {
      this.blink();
    }
  }

  render = () => (
    <Shape isBlinking={this.state.blink}>
      <Count
        isEmpty={this.props.basketItems === 0}
        isBlinking={this.state.blink}
      >
        {this.props.basketItems}
      </Count>
      <Logo />
    </Shape>
  );
}

const mapStateToProps = ({ app }) => ({
  basketItems: app.basket.reduce((acc, curr) => acc + curr.count, 0),
});

export default connect(mapStateToProps)(BasketWidget);

import React from 'react';
import styled from 'styled-components';
import { StaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { media } from '../../styles/helpers';

const query = graphql`
  query {
    fileName: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 160, cropFocus: CENTER, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

const Logo = () => (
  <StaticQuery
    query={query}
    render={({ fileName: { childImageSharp } }) => <Img {...childImageSharp} />}
  />
);

const Contaienr = styled(Link).attrs({ to: '/' })`
  display: flex;
  flex-basis: 15%;
  max-width: 190px;
  align-items: center;

  & .gatsby-image-wrapper {
    display: none;

    ${media.md`
      display: inline-block;
    `}
  }
`;

export default () => (
  <Contaienr>
    <Logo />
  </Contaienr>
);

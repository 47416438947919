import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import scrollToElement from 'scroll-to-element';
import { media } from '../../styles/helpers';
import { offsets, colors, transitions } from '../../styles';
import { navItems } from '../../data';

export const Shape = styled.nav`
  flex: 1;
  max-width: 750px;
  margin: 0 ${offsets.sm};
`;

export const Menu = styled.ul`
  width: 100%;
  display: flex;
  font-size: 12px;
  line-height: 1.34;
  font-weight: bold;
  letter-spacing: 1px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${media.sm`
    font-size: 18px;
  `}

  ${media.md`
  align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
  `}
`;

export const MenuLink = styled(Link)`
  position: relative;
  display: block;
  padding: ${offsets.sm} ${offsets.xs};

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: ${offsets.sm};
    left: ${offsets.sm};
    height: 6px;
    background: ${colors.primary};
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: transform ${transitions.base};
  }

  &:hover {
    color: ${colors.primary};
    &:before {
      transform: scaleX(1);
    }
  }

  ${media.sm`
  padding: ${offsets.lg} ${offsets.md};
  `}
`;

export const MenuItem = ({ text, to, scrollTo }) => (
  <li>
    <MenuLink
      to={to}
      onClick={e => {
        if (!scrollTo) {
          return;
        }

        e.preventDefault();
        if (window.location.pathname !== to) {
          navigate(to);
          return setTimeout(() => {
            scrollToElement(scrollTo);
          }, 50);
        }

        return scrollToElement(scrollTo);
      }}
    >
      {text}
    </MenuLink>
  </li>
);

export default () => (
  <Shape>
    <Menu>
      {navItems.map((item, idx) => (
        <MenuItem key={`header-menu-item-${idx}`} {...item} />
      ))}
    </Menu>
  </Shape>
);
